import {
  Box,
  Card,
  CardBody,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

export default function Services({ styleProps, title, services }) {
  return (
    <Box role="services" {...styleProps}>
      <Container maxW="1080">
        <Box textAlign="center" pb="30px">
          <Heading size="lg">{title}</Heading>
        </Box>
        <Box>
          <SimpleGrid
            columns={{
              sm: 3,
              base: 1,
            }}
            spacing={5}
          >
            {services?.map(({ id, title, description }) => {
              return (
                <Card key={id}>
                  <CardBody>
                    <Box>
                      <Heading size="xs">{title}</Heading>
                      <Text
                        pt="2"
                        fontSize="sm"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        noOfLines="3"
                      >
                        {description}
                      </Text>
                    </Box>
                  </CardBody>
                </Card>
              );
            })}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}
