import React from 'react';
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Flex,
  Textarea,
  Card,
  CardBody,
  Heading,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';

export const Footer = ({ ...props }) => {
  const InputField = {
    textArea: props => <Textarea {...props} />,
    input: props => <Input {...props} />,
  };
  const formDataArray = [
    {
      name: 'name',
      label: 'First Name',
      value: '',
      type: 'input',
      validate: value => {
        if (!value) {
          return `Name is required`;
        }
      },
    },
    {
      name: 'email',
      label: 'Email',
      value: '',
      type: 'input',
      validate: value => {
        if (!value) {
          return 'Email is required';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return 'Invalid email format';
        }
      },
    },
    {
      name: 'message',
      label: 'Message',
      value: '',
      type: 'textArea',
      validate: value => {
        if (!value) {
          return 'Message is required';
        }
      },
    },
  ];
  return (
    <Box as="footer" role="contactInfo" {...props}>
      <Flex direction="row" justify="center">
        <Card border={true} width="100%">
          <Flex justify="center" pt="20px">
            <Heading size="lg">Contact Us</Heading>
          </Flex>
          <CardBody>
            <Container maxW="700">
              <Formik
                initialValues={Object.fromEntries(
                  formDataArray.map(({ name, value }) => [name, value])
                )}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                  }, 1000);
                }}
              >
                {props => (
                  <Form>
                    {formDataArray.map(({ name, label, validate, type }) => (
                      <Field key={name} name={name} validate={validate}>
                        {({ field, form }) => (
                          <FormControl
                            mt="2"
                            isInvalid={form.errors[name] && form.touched[name]}
                          >
                            <FormLabel>{label}</FormLabel>
                            {InputField[type]({
                              ...field,
                              placeholder: label.toLowerCase(),
                            })}
                            <FormErrorMessage>
                              {form.errors[name]}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    ))}
                    <Flex justify="end">
                      <Button
                        mt={4}
                        colorScheme="blue"
                        isLoading={props.isSubmitting}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Container>
            <Flex mt="30px" justifyContent="center">
              © 2021 Bizremark – Software Development. All rights reserved.
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Box>
  );
};

export default Footer;
