import { Box, Button, ButtonGroup, Flex, Heading } from '@chakra-ui/react';
import { FaTv, FaRocket } from 'react-icons/fa';

export default function IntroSection({ styleProps, title, description }) {
  return (
    <Box
      role="intro"
      {...styleProps}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        direction="column"
        maxW="800px"
        alignItems="center"
        justifyContent="center"
        gap="20px"
      >
        <Heading textAlign="center" size="xl" maxW="450px">
          {title}
        </Heading>
        <Box maxW="650px" textAlign="center">
          {description}
        </Box>
        <ButtonGroup spacing="2">
          <Button colorScheme="blue" leftIcon={<FaRocket />}>
            Get Started
          </Button>
          <Button colorScheme="blue" leftIcon={<FaTv />}>
            Explore PseudoSoft
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
}
