import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/react';
import ProjectCard from './ProjectCard';

export default function ProjectList({ styleProps, title, projects }) {
  return (
    <Container maxW="1080" {...styleProps}>
      <Box pb="30px" textAlign="center">
        <Heading size="lg">{title}</Heading>
      </Box>
      <SimpleGrid
        columns={{
          sm: 3,
          base: 1,
        }}
        spacing={5}
      >
        {projects.map(project => {
          return <ProjectCard key={project.id} {...project} />;
        })}
      </SimpleGrid>
    </Container>
  );
}
