import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function GlanceCard({
  styleProps,
  title,
  description,
  imgOrder = 0,
  img,
}) {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <Box role="services" {...styleProps}>
      <Container maxW="1080">
        <Box textAlign={isMobile && 'center'}>
          <SimpleGrid
            gap="100px"
            columns={{
              sm: 2,
              base: 1,
            }}
            spacing={5}
          >
            {!isMobile && <Image order={imgOrder} src={img}></Image>}
            <Flex gap="10px" direction="column" justifyContent="center">
              <Text fontSize="25" color="blue.600" fontWeight={600}>
                {title}
              </Text>
              <Text
                fontSize="25"
                textOverflow="ellipsis"
                overflow="hidden"
                noOfLines="5"
              >
                {description}
              </Text>
              <Box pt="25px">
                <Button colorScheme="blue">Explore Now</Button>
              </Box>
            </Flex>
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}
