import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  ScaleFade,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useInViewport } from 'react-in-viewport';
import { useRef } from 'react';

export default function Footer({ desc, title, image, onClick }) {
  const ref = useRef(null);
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: '-200px' },
    { disconnectOnLeave: false },
    {}
  );

  return (
    <ScaleFade
      initialScale={0.9}
      in={enterCount > 0}
      whileHover={{ scale: 1.05 }}
    >
      <Box ref={ref} as="project-card" role="projectCard">
        <Card border={true} minH={425}>
          <CardBody>
            <Flex justify="center">
              <Image
                objectFit="cover"
                width="100%"
                maxH="200px"
                src={image}
                alt="Green double couch with wooden legs"
                borderRadius="lg"
              />
            </Flex>
            <Stack mt="6" px="5" spacing="3">
              <Heading size="md">{title}</Heading>
              <Text textOverflow="ellipsis" overflow="hidden" noOfLines="3">
                {desc}
              </Text>
            </Stack>
          </CardBody>
          <CardFooter justify="end">
            <ButtonGroup spacing="2">
              <Button
                variant="ghost"
                colorScheme="blue"
                rightIcon={<ArrowForwardIcon />}
                onClick={() => onClick()}
              >
                Explore
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </Box>
    </ScaleFade>
  );
}
