import { Flex } from '@chakra-ui/react';
import { Footer } from '../components/footer/Footer';
import { Main } from '../components/main/Main';
import { Navbar } from '../components/header/Navbar';

export const MainLayout = () => {
  return (
    <Flex direction="column" flex="1">
      <Navbar minH={'10dvh'} />
      <Main minH={'70dvh'} />
      <Footer minH={'20dvh'} />
    </Flex>
  );
};
