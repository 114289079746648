import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useBreakpointValue } from '@chakra-ui/react';
import logo from '../../assets/nuxt.svg';

export const Navbar = props => {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const links = [
    {
      label: 'Projects',
    },
    {
      label: 'Contact Us',
    },
  ];

  return (
    <Box as="nav" role="navigation" {...props}>
      <Container maxW="1080">
        <Flex
          paddingY="10px"
          justify={'space-between'}
          align={'center'}
          direction="row"
        >
          <Box>
            <Flex alignItems="center">
              <Image alt="PseudoSoft" src={logo} maxW="60px"></Image>
              <Heading size="lg">BIZREMARK</Heading>
            </Flex>
          </Box>
          <Flex gap="10px" align="center">
            {isMobile ? (
              <Menu>
                <MenuButton
                  variant="ghost"
                  as={Button}
                  rightIcon={<HamburgerIcon />}
                ></MenuButton>
                <MenuList>
                  {links.map(link => {
                    return (
                      <MenuItem key={link.label} onClick={() => {}}>
                        {link.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            ) : (
              links.map(link => {
                return (
                  <Button
                    key={link.label}
                    colorScheme="gray"
                    onClick={() => {}}
                  >
                    {link.label}
                  </Button>
                );
              })
            )}
            <ColorModeSwitcher />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
