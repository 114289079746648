import { Box } from '@chakra-ui/react';
import IntroSection from './IntroSection';
import ProjectList from './ProjectList';
import Services from './Services';
import GlanceCard from './GlanceCard';
import ListCard from './ListCard';

import ezBridgeImage from '../../assets/projects/ezbridge.png';
import authMicroservice from '../../assets/projects/authmicroservice.png';
import futApp from '../../assets/projects/futapp.png';

export const Main = props => {
  const services = [
    {
      id: 1,
      title: 'Web Development',
      description:
        "Elevate your online presence with Pseudosoft's expert web development services. We create responsive, visually stunning websites tailored to your business goals.",
    },
    {
      id: 2,
      title: 'App Development',
      description:
        "Transform your ideas into powerful mobile experiences with Pseudosoft's app development. Our team ensures your applications meet the highest standards of performance, security, and user engagement.",
    },
    {
      id: 3,
      title: 'AI/ML and VR Solutions',
      description:
        'Pseudosoft pioneers AI/ML integration for predictive analytics and automation. Dive into immersive virtual reality experiences with our cutting-edge solutions, bringing the future to your fingertips.',
    },
  ];
  const ourProjects = [
    {
      desc: 'EZ Bridge Connect provides influencers with an intuitive dashboard that presents a range of sponsorship opportunities. Browse, select, and engage in partnerships that align with your brand.',
      title: 'Ez Bridge',
      id: 1,
      image: ezBridgeImage,
    },
    {
      desc: 'Fut Trading Bot is a game-changing tool designed to enhance your trading experience in FIFA Ultimate Team (FUT). This powerful bot automates sniping and selling processes, providing you with lightning-fast search capabilities and lucrative trading opportunities that may otherwise go unnoticed.',
      title: 'Fut App',
      id: 2,
      image: futApp,
    },
    {
      desc: 'A Full stack authentication application made using quasar 2 for frontend and django rest framework for backend. The application is made completely flexible to be coupled with any other application and implements jwt token authentication.',
      title: 'Authentication Microservice',
      id: 3,
      image: authMicroservice,
    },
  ];
  return (
    <Box role="presentation" {...props}>
      <IntroSection
        styleProps={{
          h: '58vh',
        }}
        title="Innovate, Create, Bizremark Elevate"
        description="Bizremark, where innovation thrives. We are a dynamic software company dedicated to crafting cutting-edge solutions that redefine the digital landscape. With a commitment to excellence, we blend creativity and technology to elevate your experience. Embrace the future with Pseudosoft, where innovation knows no bounds."
      />
      <Services
        title="Our Services"
        services={services}
        styleProps={{
          pb: '40px',
        }}
      />
      <GlanceCard
        styleProps={{
          pb: '40px',
        }}
        title="AI/ML INNOVATION HUB"
        description="Drive innovation with our AI/ML specialists, powering your projects with cutting-edge technologies. Harness the potential of artificial intelligence and machine learning for predictive analytics, automation, and data-driven insights. Transform your business landscape with our comprehensive solutions, tailored to elevate your industry standing."
        img="https://media.giphy.com/media/89jRrowcuHEG0OFavV/giphy.gif"
      />
      {/* img="https://media.giphy.com/media/eljCVpMrhepUSgZaVP/giphy-downsized-large.gif" */}
      <GlanceCard
        imgOrder={1}
        styleProps={{
          pb: '40px',
        }}
        title="WEB DEVELOPMENT ACCELERATORS"
        description="Fuel your web projects with our talented developers, speeding up development and expanding functionality effortlessly. Elevate your web solutions with seamless integration and rapid deployment."
        img="https://media.giphy.com/media/knh6IuGKMB4ySsg47z/giphy.gif"
      />
      <GlanceCard
        styleProps={{
          pb: '40px',
        }}
        title="APP DEVELOPMENT MASTERY"
        description="Supercharge your app development endeavors with our skilled team of developers. Accelerate the creation of intuitive and feature-rich applications while ensuring seamless integration and swift deployment. Let our expertise fuel your app projects and deliver standout user experiences."
        img="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExZHB3b2lrNnJ5MGx3NWhiOXczeWdrZHgxZjRnbXI4YnFyMGVpZXRiaiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/SQTSmiJl0uKoZNdv1T/giphy.gif"
      />

      <ProjectList
        title="Our Projects"
        projects={ourProjects}
        styleProps={{
          pb: '40px',
        }}
      />
      <ListCard
        imgOrder={1}
        styleProps={{
          pb: '40px',
        }}
        title="Innovative Solutions"
        list={[
          'Constant Research: We stay at the cutting edge of technology trends through continuous research and exploration',
          'Creative Thinking: Pseudosoft fosters a culture of creativity, encouraging our team to think outside the box for innovative solutions.',
          'Industry Insight: Our in-depth industry knowledge allows us to anticipate and address the unique challenges faced by businesses.',
        ]}
        img="https://media.giphy.com/media/Y7xSJvcLPsjLG3iJEX/giphy.gif"
      />

      <ListCard
        styleProps={{
          pb: '40px',
        }}
        title="Customized Approach"
        list={[
          'Tailored solutions designed to meet the unique needs of clients.',
          'In-depth needs assessment identifies challenges and opportunities for personalized solutions.',
          'Flexible strategies adapt to evolving project requirements for customized outcomes.',
        ]}
        img="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdXlmemdqamtnbWpzaWttbmVkN3cwd245Ynk1MXltcHg5d3ppdWN5ayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/RLsfgZfNGJ3fzlMXdV/giphy.gif"
      />
      <ListCard
        imgOrder={1}
        styleProps={{
          pb: '40px',
        }}
        title="Experienced Team"
        list={[
          'Proven track record assures successful project delivery and client satisfaction.',
          'Technical proficiency guarantees implementation of industry best practices in development.',
          'Collaborative team approach ensures seamless communication and collective problem-solving skills.',
        ]}
        img="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeGIzYngxMmdobWtwMzVhbWJoOGw2dnY4MXB2M2E0ZDRobnptcjZ2aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/q7XCMEUFegs6QDg3IT/giphy.gif"
      />
    </Box>
  );
};
