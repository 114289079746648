import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaChevronCircleRight } from 'react-icons/fa';

export default function ListCard({
  styleProps,
  title,
  list,
  imgOrder = 0,
  img,
}) {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <Box role="services" {...styleProps}>
      <Container maxW="1080">
        <Box textAlign={isMobile && 'center'}>
          <SimpleGrid
            gap="100px"
            columns={{
              sm: 2,
              base: 1,
            }}
            spacing={5}
          >
            {!isMobile && <Image order={imgOrder} src={img}></Image>}
            <Flex gap="10px" direction="column" justifyContent="center">
              <Text fontSize="25" color="blue.600" fontWeight={600}>
                {title}
              </Text>
              <List spacing={2}>
                {list.map(point => {
                  return (
                    <ListItem key={point} display="flex">
                      <ListIcon
                        mt="6px"
                        as={FaChevronCircleRight}
                        color="blue.600"
                      />
                      {point}
                    </ListItem>
                  );
                })}
              </List>
              <Box pt="25px">
                <Button colorScheme="blue">Explore Now</Button>
              </Box>
            </Flex>
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}
